<template>
  <div class="row align-items-center justify-content-center g-0 py-4">
    <!-- Docs -->
    <div class="offset-xl-1 col-xl-4 col-lg-6 col-md-12 col-12 text-center text-lg-start">
      <h1 class="display-1 mb-3">404</h1>

      <p class="mb-5 lead">Oops! Trang không tồn tại</p>
      <a href="/" class="btn btn-primary me-2">Quay lại</a>
    </div>
    <div class="offset-xl-1 col-xl-6 col-lg-6 col-md-12 col-12 mt-8 mt-lg-0">
      <img src="@/../public/assets/images/error/404-error-img.svg" alt="" class="w-100">
    </div>
  </div>
</template>

<script>

export default {
  name: '404'
}
</script>
